import "./App.css";
import img4 from "./img4.jpg";
import insta from "./instagram.svg";

function App() {
  const link = () => {
    window.open("https://www.instagram.com/petitebeautybycrianne", "_blank");
  };
  return (
    <div className="hero">
      <div className="heroText">
        <h1>
          Hi, I'm <span>Crianne!</span>
        </h1>
        <h3>Beauty Therapist, Mom + Full-Time Doggo Lover 🐶</h3>
        {/* <button >Contact Me</button> */}
        <img
          alt="petite beauty instagram"
          onClick={link}
          className="insta"
          src={insta}
        />
      </div>
      <div className="heroImgWrapper">
        <img alt="Crianne Smith" className="heroImg" src={img4} />
      </div>
    </div>
  );
}

export default App;
